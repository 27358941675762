<template>
  <AdminPageFrame>
    <PageTitle>
      {{ trans('PAGE_TITLE:Subscriptions') }}
    </PageTitle>
    <Loader v-if="packagesDataLoading" class="h-050screen"/>
    <t-card v-else class="w-full">
      <div class="w-full lg:min-w-modal-gallery" v-if="packagesData">
        <DataTable :data="packagesData" :headers="tableHeaders" @edit="editPackage" @delete="deletePackage">
          <template #ageRange="{ item }">
            {{ item.ageMin + ' - ' + item.ageMax + ' ' + trans('LABEL:years') }}
          </template>
          <template #name="{ item }">
            {{ trans(item.name) }}
          </template>
        </DataTable>
      </div>
    </t-card>
  </AdminPageFrame>
</template>

<script>
import AdminPageFrame from "@/views/content/admin/components/AdminPageFrame";
import Button from "@/utils/crud/components/Button";
import DataTable from "@/utils/table/DataTable";
import Loader from "@/views/Loader";
import Modal from "@/utils/modal/Modal";
import PageTitle from "@/layouts/components/pages/PageTitle";
import Pagination from "@/views/content/components/Pagination";
import router from "@/router";
import {mapActions, mapGetters} from "vuex";

export default {
  name: "SubscriptionsList",
  components: {
    AdminPageFrame,
    Button,
    DataTable,
    Loader,
    Modal,
    PageTitle,
    Pagination,
  },
  data() {
    return {
      tableHeaders: [
        {
          name: 'LABEL:Actions',
          value: 'actions',
        },
        {
          name: 'ID',
          value: 'id'
        },
        {
          name: 'LABEL:Name',
          value: 'name'
        },
        {
          name: 'LABEL:Price',
          value: 'price',
        },
        {
          name: 'LABEL:SalePrice',
          value: 'salePrice',
        },
        {
          name: 'LABEL:Gender',
          value: 'genderDesc',
        },
        {
          name: 'LABEL:AgeRange',
          value: 'ageRange',
        },
        {
          name: 'LABEL:Days',
          value: 'days'
        },
      ],
      currentPage: 1,
      isShowDeletePackageModal: false,
      packageToDelete: null,
    }
  },
  watch: {
    packageDeleted(val) {
      if(val) {
        this.packageToDelete = null;
        this.isShowDeletePackageModal = false;
        this.currentPage = 1;
      }
    }
  },
  computed: {
    ...mapGetters('packages', ['packagesData', 'packagesDataLoading', 'pagination', 'packageDeleting', 'packageDeleted'])
  },
  methods: {
    ...mapActions('packages', ['fetchShowPackageRequest', 'fetchPackagesRequest', 'deletePackageRequest']),
    next(val) {
      this.currentPage = val;
      this.fetchPackagesRequest({
        page: val,
      });
    },
    deletePackage(id) {
	    this.deletePackageRequest({id: id})

    },
    editPackage(id) {
      router.push({name: 'admin-package-show', params: {id: id}})
    },
  },
  mounted() {
    this.fetchPackagesRequest({page: this.currentPage});
  }
}
</script>
